var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _vm.responseData.length == 0
      ? _c(
          "div",
          { staticStyle: { "text-align": "center", "font-size": "20px" } },
          [_vm._v("\n    Không có dữ liệu\n  ")]
        )
      : _c(
          "span",
          _vm._l(_vm.responseData, function (item) {
            return _c("div", { key: item }, [
              _c("iframe", {
                attrs: {
                  width: "100%",
                  height: "920",
                  allowfullscreen: "",
                  src: item,
                },
              }),
            ])
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }